import { lazy, Suspense } from 'react';
import { EditPackageOverlayParams, packagePiletOverlays } from '@sdk/extensionTypes';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';

export * from './getDefaultOverlayOptions';

export type EditPackageOverlayProps = EditPackageOverlayParams &
	Pick<
		OverlayDrawerComponentProps<typeof packagePiletOverlays.editPackageDrawer>,
		'completeInteraction' | 'cancelInteraction' | 'options'
	>;

const LazyEditPackageOverlayContent = lazy(() => import('./editPackageOverlay'));

export const EditPackageOverlay = (props: EditPackageOverlayProps) => (
	<Suspense fallback={<Spin />}>
		<LazyEditPackageOverlayContent {...props} />
	</Suspense>
);
